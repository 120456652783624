import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import videojs from "video.js";
import "video.js/dist/video-js.css";

function Player({
  className = "",
  video,
  options = {},
  onReady = player => {},
  onFirstPlay = e => {},
  onPlay = e => {},
  onEnded = e => {}
}) {
  const videoRef = useRef();
  const playerRef = useRef();
  const volumePanelRef = useRef();

  options = {
    controls: true,
    controlBar: {
      pictureInPictureToggle: false
    },
    muted: false,
    autoplay: false,
    ...options
  };

  const [muted, setMuted] = useState(options.muted);

  const root = document.createElement("div");
  const [tooltipRoot, setTooltipRoot] = useState(root);

  const Tooltip = ({ hidden }) => {
    return createPortal(
      hidden ? null : (
        <div className="bl-videoPlayer-tooltip">ここから音量を変更できます</div>
      ),
      tooltipRoot
    );
  };

  useEffect(
    () => {
      let player;
      if (!playerRef.current) {
        const element = videoRef.current;
        if (!element) return;

        player = playerRef.current = videojs(element, options, () => {
          player.src([
            { type: "application/x-mpegURL", src: video.playlist.hls }
            //{ type: "application/dash+xml", src: video.playlist.mpd }
          ]);
          player.on("play", onPlay);
          player.one("playing", onFirstPlay);
          player.on("ended", onEnded);

          volumePanelRef.current = player.controlBar.volumePanel.el();
          volumePanelRef.current.appendChild(tooltipRoot);
          setTooltipRoot(tooltipRoot);
          player.on("volumechange", () => {
            setMuted(player.muted() || player.volume() === 0);
          });

          onReady(player);
        });
      } else {
        volumePanelRef.current.appendChild(tooltipRoot);
      }
      return () => {
        volumePanelRef.current.removeChild(tooltipRoot);
      };
    },
    [
      options,
      videoRef,
      video,
      onReady,
      onFirstPlay,
      onPlay,
      onEnded,
      tooltipRoot
    ]
  );

  useEffect(
    () => {
      /** @type Player */
      const player = playerRef.current;
      return () => {
        player.dispose();
        playerRef.current = null;
      };
    },
    [playerRef]
  );

  return (
    <div className={`bl-videoPlayer ${className}`}>
      {video ? (
        <video
          ref={videoRef}
          controls
          preload="auto"
          className="bl-videoPlayer-video video-js vjs-big-play-centered"
        />
      ) : null}
      <Tooltip hidden={!muted} />
    </div>
  );
}

export default Player;
