import React, { useEffect, useState } from "react";
import { Container } from "uikit-react";
import Player from "../common/player";
import { useParams } from "react-router-dom";
import { fetchWatchInfo, incrementPlayCount } from "../../api/video";
import useTitle from "../../hooks/title";
import { useAuthUserId } from "../../hooks/authuser";
import Alert from "../common/alert";

function WatchPage() {
  const params = useParams();
  const [video, setVideo] = useState(null);
  const [notFound, setNotFound] = useState(false);

  const userId = useAuthUserId();

  const basename = params.basename;
  const title = video ? video.hospital_name : "動画再生";
  useTitle(() => title, title);

  const onFirstPlay = () => {
    (async () => {
      try {
        await incrementPlayCount(basename);
      } catch (e) {
        //
      }
    })();
  };

  useEffect(
    () => {
      (async () => {
        try {
          const video = await fetchWatchInfo(basename);
          setVideo(video);
        } catch (e) {
          setNotFound(true);
        }
      })();
    },
    [basename]
  );

  return (
    <Container className="pg-videoWatch">
      {!notFound ? (
        video ? (
          <div>
            <div className="bl-pageHeader">
              <div className="uk-grid uk-flex-middle">
                <div className="uk-width-3-5">
                  <h1 className="bl-pageHeader-title">{title}</h1>
                </div>
              </div>
            </div>
            {userId ? (
              <Alert theme="primary" icon="play-circle" title="プレビューモード">
                <p className="uk-text-small">
                  ログインした状態で再生画面を開いた場合は再生回数としてカウントされません。
                </p>
              </Alert>
            ) : null}
            {video.encoded_at ? (
              <Player
                video={video}
                options={{ muted: true, autoplay: true }}
                onFirstPlay={onFirstPlay}
                className="uk-margin-medium-bottom"
              />
            ) : (
              <p>
                この動画は 変換処理が完了していないため、まだ再生できません。
                <br />
                恐れ入りますが、少し時間を置いてからもう一度アクセスしてください。
              </p>
            )}
          </div>
        ) : null
      ) : (
        <div>
          <div className="bl-pageHeader">
            <div className="uk-grid uk-flex-middle">
              <div className="uk-width-1-1">
                <h1 className="bl-pageHeader-title">404 Not Found</h1>
              </div>
            </div>
          </div>
          <p>
            該当する動画は存在しない、または有効期限が切れたため、表示できません。
          </p>
        </div>
      )}
    </Container>
  );
}

export default WatchPage;
