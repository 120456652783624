import { useEffect, useState } from "react";

function ConfirmModal({
  id = "js-modal",
  show = false,
  title = "確認",
  accept = "OK",
  cancel = "キャンセル",
  onAccept = e => {},
  onCancel = e => {},
  theme = "primary",
  children
}) {
  const [open, setOpen] = useState(false);
  const [block, setBlock] = useState(false);
  useEffect(
    () => {
      let timer = null;
      if (show) {
        setBlock(show);
        timer = setTimeout(() => {
          setOpen(show);
        }, 50);
      } else {
        setOpen(show);
        timer = setTimeout(() => {
          setBlock(show);
        }, 1000);
      }
      return () => {
        clearTimeout(timer);
      };
    },
    [show]
  );

  return (
    <div
      id={id}
      className={`bl-modal uk-modal ${open ? "uk-open" : ""}`}
      style={{ display: block ? "block" : "none" }}
    >
      <div className="bl-modal-dialog uk-modal-dialog uk-modal-body">
        <h2 className="bl-modal-title uk-modal-title">{title}</h2>
        {children ? <div className="bl-modal-body">{children}</div> : null}
        <p className="bl-modal-control uk-text-right">
          <button
            className="uk-button uk-button-default"
            type="button"
            onClick={onCancel}
          >
            {cancel}
          </button>
          <button
            className={`uk-button uk-button-${theme}`}
            type="button"
            onClick={onAccept}
          >
            {accept}
          </button>
        </p>
      </div>
    </div>
  );
}
export default ConfirmModal;
