import { apiGet, apiPatch } from "./api";

export async function getSettings() {
  const response = await apiGet(`/settings`);
  return response.data;
}

export async function saveSettings(settings) {
  const response = await apiPatch(`/settings`, settings);
  return response.data;
}
