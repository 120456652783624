import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Icon } from "uikit-react";
import { searchUsers } from "../../api/user";
import { useCompactQuery, useFullQuery } from "../../hooks/query";
import { useAuthUserRole } from "../../hooks/authuser";
import Pagination from "../common/pagination";
import SearchBox from "../common/searchbox";
import PlaceFilter from "../common/placefilter";
import UserList from "./list";
import { apiRequest } from "../../stores/slices/auth";
import useTitle from "../../hooks/title";

function UserListPage() {
  useTitle("ユーザー管理");

  const dispatch = useDispatch();
  const [response, setResponse] = useState();
  const userRole = useAuthUserRole();

  const fullQuery = useFullQuery(
    useMemo(
      () => ({
        p: 1,
        s: "",
        placeId: ""
      }),
      []
    )
  );

  const query = useCompactQuery(fullQuery);
  const [update, setUpdate] = useState(null);

  useEffect(
    () => {
      dispatch(apiRequest(searchUsers(fullQuery))).then(action => {
        if (apiRequest.fulfilled.match(action)) {
          setResponse(action.payload);
        }
      });
    },
    [dispatch, fullQuery, update]
  );

  const refresh = e => {
    setUpdate(Date.now());
  };

  return (
    <Container className="pg-videoList">
      <div className="bl-pageHeader">
        <div className="uk-grid uk-flex-middle">
          <div className="uk-width-3-5">
            <h1 className="bl-pageHeader-title">ユーザー管理</h1>
          </div>
          <div className="uk-width-2-5 uk-text-right">
            <Link to="/users/new" className="uk-button uk-button-primary">
              <Icon
                options="icon: plus-circle;"
                className="uk-text-middle uk-margin-small-right"
              />
              <span className="uk-text-middle">
                <span className="uk-visible@s">新しくユーザーを</span>追加
              </span>
            </Link>
          </div>
        </div>
        <div className="uk-grid uk-flex-between uk-flex-middle uk-margin">
          <div className="uk-width-2-3@s">
            {userRole.manage_all_users ? (
              <PlaceFilter query={fullQuery} />
            ) : null}
          </div>
          <div className="uk-width-1-3@s uk-text-right uk-margin-top uk-margin-remove-top@s uk-width-1-4@l">
            <SearchBox query={fullQuery} placeholder="ユーザーを検索..." />
          </div>
        </div>
      </div>
      {response ? (
        response.data.length ? (
          <div>
            <UserList items={response.data} refresh={refresh} />
            <div className="bl-pageFooter">
              <Pagination
                query={query}
                current={response.current_page}
                last={response.last_page}
              />
            </div>
          </div>
        ) : (
          <p className="uk-text-middle">
            該当するユーザーが見つかりません。
            <br />
            条件を変えてお試しください。
          </p>
        )
      ) : null}
    </Container>
  );
}

export default UserListPage;
