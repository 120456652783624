import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Container } from "uikit-react";
import UIkit from "uikit";
import { getSettings, saveSettings } from "../../api/setting";
import { formToObject } from "../../utilities";
import Error from "../common/error";
import {apiRequest} from "../../stores/slices/auth";
import useTitle from "../../hooks/title";

function SettingPage() {
  useTitle("システム設定");

  const dispatch = useDispatch();
  const [settings, setSettings] = useState(null);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    dispatch(apiRequest(getSettings())).then(action => {
      if (apiRequest.fulfilled.match(action)) {
        setSettings(action.payload);
      }
    });
  }, [dispatch]);

  const onSubmit = e => {
    e.preventDefault();

    const data = formToObject(e.target);
    saveSettings(data)
      .then(() => {
        UIkit.notification({
          message: "設定を保存しました",
          status: "primary",
          timeout: 2000
        });
      })
      .catch(e => {
        let message = "登録エラー";
        const data = e.response.data;
        if (data) {
          if (data.message) {
            message = data.message;
          }
          if (data.errors) {
            setErrors(data.errors);
          }
        }
        UIkit.notification({
          message,
          status: "danger",
          timeout: 2000
        });
      });
  };

  return (
    <Container className="pg-videoList">
      <div className="bl-pageHeader">
        <div className="uk-grid uk-flex-middle">
          <div className="uk-width-1-1">
            <h1 className="bl-pageHeader-title">システム設定</h1>
          </div>
        </div>
      </div>
      <p className="uk-margin-medium-bottom">
        この画面での設定内容は全ユーザーに適用されますのでご注意ください。
        <br />
        デフォルト再生期間とデフォルト再生回数については
        アップロード済みの動画には影響しません。
      </p>
      {settings ? (
        <form action="" method="post" onSubmit={onSubmit}>
          <div className="uk-grid">
            <div className="uk-width-1-2@m">
              <div className="uk-form-horizontal uk-margin-large">
                <div className="uk-margin">
                  <label
                    className="uk-form-label"
                    htmlFor="form-horizontal-select"
                  >
                    デフォルト再生可能期間
                  </label>
                  <div className="uk-form-controls">
                    <Error messages={errors.default_limit_days} />
                    <input
                      type="number"
                      name="default_limit_days"
                      defaultValue={settings.default_limit_days}
                      className="uk-input uk-form-width-xsmall uk-text-center"
                      min="0"
                    />
                    <span className="uk-inline uk-width-auto uk-input uk-form-blank">
                      日間（0で無期限）
                    </span>
                  </div>
                </div>
                <div className="uk-margin">
                  <label className="uk-form-label">
                    デフォルト再生可能回数
                  </label>
                  <div className="uk-form-controls">
                    <Error messages={errors.default_limit_count} />
                    <input
                      type="number"
                      name="default_limit_count"
                      defaultValue={settings.default_limit_count}
                      className="uk-input uk-form-width-xsmall uk-text-center"
                      min="0"
                    />
                    <span className="uk-inline uk-width-auto uk-input uk-form-blank">
                      回（0で無制限）
                    </span>
                  </div>
                </div>
                <div className="uk-margin">
                  <label className="uk-form-label">物理削除までの日数</label>
                  <div className="uk-form-controls">
                    <Error messages={errors.video_remove_days} />
                    <input
                      type="number"
                      name="video_remove_days"
                      defaultValue={settings.video_remove_days}
                      className="uk-input uk-form-width-xsmall uk-text-center"
                      min="1"
                    />
                    <span className="uk-inline uk-width-auto uk-input uk-form-blank">
                      日後
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bl-pageFooter">
            <div className="uk-grid uk-flex-right">
              <div className="uk-width-1-2@m uk-text-right">
                <button type="submit" className="uk-button uk-button-primary">
                  設定を保存
                </button>
              </div>
            </div>
          </div>
        </form>
      ) : null}
    </Container>
  );
}

export default SettingPage;
