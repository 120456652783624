import {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import VideoListRow from "./row";
import ConfirmModal from "../common/modal/confirm";
import {
  deleteMultipleVideos,
  deleteSingleVideo,
  restoreSingleVideo
} from "../../api/video";
import UIkit from "uikit";

function VideoList({ query, items, refresh = e => {} }) {
  const navigate = useNavigate();

  const [selectedItems, setSelectedItems] = useState([]);
  const controlEnabled = selectedItems.length > 0;
  const allItemsSelected = selectedItems.length >= items.length;

  const onChange = e => {
    const value = e.target.value;
    let items = [...selectedItems, value];
    if (!e.target.checked) {
      items = selectedItems.filter(id => id !== value);
    }
    setSelectedItems(items);
  };

  const selectReset = e => {
    setSelectedItems([]);
  };

  useEffect(() => {
    selectReset();
  }, [items]);

  const selectAll = e => {
    const selectedItems = allItemsSelected
      ? []
      : items.map(item => String(item.id));
    setSelectedItems(selectedItems);
  };

  const SelectAllButton = ({ className = "" }) => {
    let checked = allItemsSelected ? "is-checked" : "";
    if (!checked && controlEnabled) {
      checked = "is-indeterminate";
    }
    return (
      <button
        className={`uk-checkbox ${checked} ${className}`}
        onClick={selectAll}
      />
    );
  };

  const [isRestore, setRestore] = useState(false);
  const actionName = isRestore ? "復元" : "削除";

  const [isShowConfirm, setShowConfirm] = useState(false);
  const [target, setTarget] = useState(null);

  const confirmRestoreVideo = item => {
    setRestore(true);
    setTarget(item);
    setShowConfirm(true);
  };

  const confirmDeleteSingleVideo = item => {
    setRestore(false);
    setTarget(item);
    setShowConfirm(true);
  };

  const confirmDeleteMultipleVideo = e => {
    e.preventDefault();
    setRestore(false);
    setTarget(null);
    setShowConfirm(true);
  };

  const action = e => {
    (async () => {
      setShowConfirm(false);

      const video = target;
      setTarget(null);

      const ids = selectedItems;
      setSelectedItems([]);

      try {
        if (video) {
          if (isRestore) {
            await restoreSingleVideo(video);
          } else {
            await deleteSingleVideo(video);
          }
        } else {
          await deleteMultipleVideos(ids);
        }
        UIkit.notification({
          message: `${actionName}しました`,
          status: "primary",
          timeout: 2000
        });
        if (isRestore) {
          navigate(`/videos/edit/${video.id}`);
        } else {
          refresh();
        }
      } catch (e) {
        let message = `${actionName}エラー`;
        if (e.response.data && e.response.data.message) {
          message = e.response.data.message;
        }
        UIkit.notification({
          message,
          status: "danger",
          timeout: 2000
        });
      }
    })();
  };

  const onCancel = e => {
    setShowConfirm(false);
  };

  return (
    <div className="uk-overflow-auto">
      <table className="uk-table uk-table-middle uk-table-divider bl-videoList">
        <thead>
          <tr>
            <th className="uk-table-shrink">
              <SelectAllButton
                className={`${query.deletedOnly ? "uk-invisible" : ""}`}
              />
            </th>
            {controlEnabled ? (
              <th className="el-controlCell">
                <span className="uk-text-bold">選択した動画を</span>{" "}
                <button
                  type="submit"
                  className="uk-button uk-button-small uk-button-danger"
                  onClick={confirmDeleteMultipleVideo}
                >
                  削除
                </button>
              </th>
            ) : (
              <th className="uk-table-expand">動画</th>
            )}
            <th className="uk-visible@s uk-width-1-6 uk-text-nowrap">
              アップロード日
            </th>
            <th className="uk-visible@s uk-table-shrink uk-text-nowrap">
              視聴期限
            </th>
            <th className="uk-visible@s uk-table-shrink uk-text-nowrap uk-text-right">
              視聴回数
            </th>
          </tr>
        </thead>
        <tbody>
          {items.map(item => (
            <VideoListRow
              id={`item-${item.id}`}
              key={`item-${item.id}`}
              video={item}
              onChange={onChange}
              onDelete={e => confirmDeleteSingleVideo(item)}
              onRestore={e => confirmRestoreVideo(item)}
              checked={selectedItems.includes(String(item.id))}
              deletable={!query.deletedOnly}
            />
          ))}
        </tbody>
      </table>
      <ConfirmModal
        show={isShowConfirm}
        accept={actionName}
        onAccept={action}
        onCancel={onCancel}
      >
        <p>
          {target ? (
            <span>動画「{target.patient_name}」</span>
          ) : (
            <span>選択した動画</span>
          )}
          を{actionName}してもよろしいですか？
        </p>
        {isRestore ? (
          <p>
            ※復元後は再生可能期間と再生可能回数が「無制限」に変更されるため、必要に応じて再設定が必要です。
          </p>
        ) : (
          <p>※削除後は配布したQRからの動画再生ができなくなります。</p>
        )}
      </ConfirmModal>
    </div>
  );
}

export default VideoList;
