import { filterValues } from "../../utilities";
import { useSearchParams } from "react-router-dom";
import Select from "../common/element/select";

function StatusFilter({ query }) {
  const [, setSearchParams] = useSearchParams();

  const filterByStatus = e => {
    e.preventDefault();
    setSearchParams(
      filterValues({ ...query, p: 1, deletedOnly: e.target.value })
    );
  };

  return (
    <form className="uk-search uk-search-default uk-width-auto@s">
      <Select
        name="deleted_only"
        defaultValue={query.deletedOnly}
        onChange={filterByStatus}
        empty="再生可能な動画"
        items={[
          { label: "期限切れ または 削除済の動画", value: "1" }
        ]}
      />
    </form>
  );
}

export default StatusFilter;
