function Checkbox({
  id = "",
  className = "",
  checked = false,
  onChange = e => {},
  value = 1,
  disabled = false
}) {
  return (
    <input
      type="checkbox"
      className={`uk-checkbox ${className}`}
      id={id}
      onChange={onChange}
      value={value}
      checked={checked}
      disabled={disabled}
    />
  );
}

export default Checkbox;
