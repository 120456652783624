function Error({ messages }) {
  return messages ? (
    <div className="bl-errorMessages">
      {messages.map((message, i) => (
        <p key={`error-${i}`} className="bl-errorMessages-text">{message}</p>
      ))}
    </div>
  ) : null;
}

export default Error;
