import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import UIkit from "uikit";
import { createVideo, progress, upload } from "../../stores/slices/upload";

function VideoUploadModal({ close = () => {} }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onDropAccepted = (files, e) => {
    dispatch(createVideo()).then(action => {
      if (createVideo.fulfilled.match(action)) {
        const video = action.payload;
        dispatch(
          upload({
            video,
            file: files[0],
            onUploadProgress: e => {
              const { loaded, total } = e;
              dispatch(progress({ loaded, total }));
            }
          })
        );
        navigate(`/videos/edit/${video.id}`);
      } else {
        UIkit.notification("登録エラー", { status: "danger" });
      }
    });
  };

  const onDropRejected = (rejections, e) => {
    UIkit.notification({
      message: "アップロードできません<br>サイズや形式をご確認ください",
      status: "danger",
      timeout: 2000
    });
    //console.error(rejections);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept : { 
      'video/mp4' : ["mp4"] ,
      'video/x-m4v' : [ "m4v" ] ,
      'video/quicktime' : [ "mov" ] ,
    } ,
    maxSize: 1024 * 1024 * 300,
    onDropAccepted,
    onDropRejected,
    useFsAccessApi : process.env.USE_FS_ACCESS_API
  });

  return (
    <div className="bl-videoUpload uk-animation-fade uk-animation-fast">
      <div className="bl-videoUpload-box uk-card uk-card-default uk-card-small uk-card-body uk-animation-scale-up uk-animation-fast">
        <div
          className={`bl-videoUpload-dropzone ${
            isDragActive ? "is-active" : ""
          }`}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <div className="bl-videoUpload-dropzone-main">
            <div className="bl-videoUpload-dropzone-text">
              <h2 className="uk-text-middle uk-text-bold uk-visible@m">
                ここに動画ファイルをドラッグ
              </h2>
              <p className="uk-text-bold">
                <span className="uk-visible@m">または </span>
                ここをクリックして動画を選択
              </p>
              <p className="uk-margin-medium uk-text-nowrap">
                対象動画形式 : mp4, m4v, mov <wbr />
                最大ファイルサイズ: 300MB
              </p>
              <p className="uk-text-nowrap">
                動画の情報は
                <wbr />
                アップロードを
                <wbr />
                開始すると
                <wbr />
                入力可能です。
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="bl-videoUpload-close">
        <button
          type="button"
          className="uk-icon-button"
          data-uk-icon="close"
          onClick={close}
        >
          {/* × */}
        </button>
      </div>
    </div>
  );
}

export default VideoUploadModal;
