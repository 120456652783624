import { useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { filterValues } from "../../utilities";

function SearchBox({ query, placeholder = "検索..." }) {
  const [, setSearchParams] = useSearchParams();

  const text = useRef();
  const search = e => {
    e.preventDefault();
    setSearchParams(filterValues({ ...query, p: 1, s: text.current.value }));
  };

  return (
    <form
      className="uk-search uk-search-default uk-width-1-1"
      onSubmit={search}
    >
      <button
        type="submit"
        className="uk-search-icon-flip"
        data-uk-search-icon
      />
      <input
        className="uk-search-input"
        type="search"
        placeholder={placeholder}
        defaultValue={query.s}
        ref={text}
      />
    </form>
  );
}

export default SearchBox;
