import { apiDelete, apiGet, apiPatch, apiPost } from "./api";

export async function searchUsers({ p, s, placeId }) {
  const response = await apiGet(
    `/users?page=${p}&s=${encodeURIComponent(s)}&place_id=${placeId}`
  );
  return response.data;
}

export async function fetchUser(id) {
  const response = await apiGet(`/users/${id}`);
  return response.data;
}

export async function storeUser(user) {
  const response = await apiPost(`/users`, user);
  return response.data;
}

export async function updateUser(user) {
  const response = await apiPatch(`/users/${user.id}`, user);
  return response.data;
}

export async function deleteUser(user) {
  const response = await apiDelete(`/users/${user.id}`);
  return response.data;
}

export async function deleteMultipleUsers(ids) {
  const response = await apiPost(`/users/delete`, { ids });
  return response.data;
}
