import React, {useEffect, useMemo, useState} from "react";

function Select({
  name = "",
  defaultValue = "",
  onChange = e => {},
  className = "uk-select",
  items = [],
  empty = "選択してください"
}) {
  const [value, setValue] = useState(defaultValue);

  useEffect(
    () => {
      setValue(defaultValue);
    },
    [defaultValue, items]
  );

  const changeValue = e => {
    setValue(e.target.value);
    onChange(e);
  };

  const emptyItem = useMemo(
    () => (Array.isArray(empty) ? empty : [empty, ""]),
    [empty]
  );

  return (
    <select
      name={name}
      value={value}
      onChange={changeValue}
      className={className}
    >
      {empty != null ? (
        <option value={emptyItem[1]}>{emptyItem[0]}</option>
      ) : null}
      {items.map(item => (
        <option value={item.value} key={`${name}-${item.value}`}>
          {item.label}
        </option>
      ))}
    </select>
  );
}

export default Select;
