import { useSearchParams } from "react-router-dom";
import { filterValues } from "../../utilities";
import PlaceSelect from "./placeselect";

function PlaceFilter({ query }) {
  const [, setSearchParams] = useSearchParams();

  const filterByPlaceId = e => {
    e.preventDefault();
    setSearchParams(filterValues({ ...query, p: 1, placeId: e.target.value }));
  };

  return (
    <form className="uk-search uk-search-default uk-width-1-1 uk-width-auto@s">
      <PlaceSelect
        empty={["全ての施設", 0]}
        defaultValue={query.placeId}
        onChange={filterByPlaceId}
      />
    </form>
  );
}

export default PlaceFilter;
