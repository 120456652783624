import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice
} from "@reduxjs/toolkit";
import { getNotices } from "../../api/notice";

const noticeAdapter = createEntityAdapter();

export const reloadNotices = createAsyncThunk("notices/reload", async () => {
  return await getNotices();
});

const initialState = noticeAdapter.getInitialState();

export const noticeSlice = createSlice({
  name: "notices",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(reloadNotices.fulfilled, (state, action) => {
      noticeAdapter.setAll(state, action.payload);
    });
  }
});

export const noticeSelector = noticeAdapter.getSelectors(state => state.notices);

export default noticeSlice.reducer;