import React, { useCallback, useEffect } from "react";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes
} from "react-router-dom";

import { loginCheck, logout } from "./stores/slices/auth";

import LoginPage from "./views/login";
import PasswordResetPage from "./views/password";
import PasswordResetByUserForm from "./views/password/reset_by_user";
import Dashboard from "./views/dashboard";
import VideoListPage from "./views/videos";
import UserListPage from "./views/users";
import SettingPage from "./views/settings";
import UserEditPage from "./views/users/edit";
import VideoEditPage from "./views/videos/edit";
import WatchPage from "./views/watch";
import { useDispatch, useSelector } from "react-redux";
import Error403 from "./views/errors/error403";
import UIkit from "uikit";
import { useAuthUserId, useAuthUserRole, useAuthUserPasswordTemporary } from "./hooks/authuser";
import Error404 from "./views/errors/error404";

function App() {
  const dispatch = useDispatch();

  //ここで user ごと取得してしまうと 表示名が変わったときに画面全体がリロードされるので
  //必要最低限の ID と ロールのみを監視する
  const userId = useAuthUserId();
  const userRole = useAuthUserRole();

  const loginChecked = useSelector(state => state.auth.checked);
  const expired = useSelector(state => state.auth.expired);
  const passwordTemporary = useAuthUserPasswordTemporary();

  useEffect(
    () => {
      dispatch(loginCheck());
    },
    [dispatch]
  );

  const LoginRequired = useCallback(
    () => {
      return userId ? <Outlet /> : <Navigate to="/login" />;
    },
    [userId]
  );

  const PermissionRequired = useCallback(
    ({ permission }) => {
      return userRole[permission] ? <Outlet /> : <Error403 />;
    },
    [userRole]
  );

  const Logout = () => {
    dispatch(logout());
    return null;
  };

  useEffect(
    () => {
      if (expired) {
        UIkit.notification({
          message: "ログインしてください",
          status: "danger",
          timeout: 2000
        });
        dispatch(logout());
      }
    },
    [dispatch, expired]
  );

  return (
    <div className="ly-app">
      {loginChecked ? (
        userId && passwordTemporary === true ? (
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Navigate to="/login" />} />
              <Route path="/login" element={<LoginPage />} />
              <Route element={<PasswordResetPage />}>
                <Route path="/password/reset_by_user/" element={<PasswordResetByUserForm />}/>
              </Route>
              <Route path="*" element={<Navigate to="/password/reset_by_user" />} />
            </Routes>
          </BrowserRouter>
        ) : (
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Navigate to="/login" />} />
            <Route
              exact
              path="/login"
              element={userId && passwordTemporary === false ? <Navigate to="/videos" /> : <LoginPage />}
            />
            <Route element={<PasswordResetPage />}>
              <Route path="/password/reset_by_user/" element={<PasswordResetByUserForm />} />
            </Route>
            <Route element={<Dashboard noNavigation={true} />}>
              <Route path="*" element={<Error404 />} />
              <Route path="/watch/:basename" element={<WatchPage />} />
            </Route>
            <Route element={<LoginRequired />}>
              <Route exact path="/" element={<Navigate to="/videos" />} />
              <Route path="/logout" element={<Logout />} />
              <Route element={<Dashboard />}>
                <Route path="/videos" element={<VideoListPage />} />
                <Route path="/videos/edit/:id" element={<VideoEditPage />} />
                <Route
                  path="/account"
                  element={<UserEditPage isMyPage={true} />}
                />
                <Route
                  element={
                    <PermissionRequired permission="partial_user_manage" />
                  }
                >
                  <Route path="/users" element={<UserListPage />} />
                  <Route path="/users/new" element={<UserEditPage />} />
                  <Route path="/users/edit/:id" element={<UserEditPage />} />
                </Route>
                <Route
                  element={
                    <PermissionRequired permission="partial_system_manage" />
                  }
                >
                  <Route path="/settings" element={<SettingPage />} />
                </Route>
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
        )
      ) : null}
    </div>
  );
}

export default App;
