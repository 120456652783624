import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { filterValues } from "../utilities";

export function useFullQuery(defaultValues) {
  const [searchParams] = useSearchParams();

  return useMemo(
    () => {
      const values = {};
      Object.keys(defaultValues).forEach(key => {
        values[key] = searchParams.get(key);
        if (values[key] == null || values[key] === "") {
          values[key] = defaultValues[key];
        }
      });
      return values;
    },
    [defaultValues, searchParams]
  );
}

export function useCompactQuery(fullQuery) {
  return useMemo(() => filterValues(fullQuery), [fullQuery]);
}
