import { format } from "date-fns";
import ja from "date-fns/locale/ja";

export function dateFormat(
  date,
  pattern = "yyyy/M/d HH:mm",
  defaultValue = "-"
) {
  if (!date) {
    return defaultValue;
  }
  if (typeof date === "string") {
    date = new Date(date);
  }
  return format(date, pattern, { locale: ja });
}

export function durationFormat(seconds) {
  if (String(seconds).match(/:/)) {
    return seconds;
  }
  const m = Math.floor(seconds / 60);
  const s = Math.floor(seconds % 60);
  const ss = `0${s}`.slice(-2);
  return `${m}:${ss}`;
}

export function filterValues(values) {
  const filtered = {};
  Object.keys(values).forEach(key => {
    const value = values[key];
    if (value != null && value !== "") {
      filtered[key] = value;
    }
  });
  return filtered;
}

export function formToObject(form) {
  const formData = new FormData(form);
  const object = {};
  formData.forEach((value, key) => (object[key] = value));
  return object;
}

export function getEnvironmentName() {
  switch (window.location.hostname) {
    case "peony.hmw.gr.jp":
      return "production";
    case "peony-test.hmw.gr.jp":
      return "test";
    case "localhost":
      return "local";
    default:
      break;
  }
}