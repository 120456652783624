import { configureStore } from "@reduxjs/toolkit";

import auth from "./slices/auth";
import upload from "./slices/upload";
import roles from "./slices/roles";
import places from "./slices/places";

export const store = configureStore({
  reducer: {
    auth,
    upload,
    roles,
    places
  }
});
