import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import UIkit from "uikit";
import { Container } from "uikit-react";
import PlaceSelect from "../common/placeselect";
import RoleSelect from "../common/roleselect";
import { useAuthUserId, useAuthUserRole, useAuthUserPlace } from "../../hooks/authuser";
import { fetchUser, storeUser, updateUser } from "../../api/user";
import { formToObject } from "../../utilities";
import Error from "../common/error";
import { apiRequest, loginCheck } from "../../stores/slices/auth";
import useTitle from "../../hooks/title";
import { STAFF_ROLE_ID, FACILITY_MANAGER_ROLE_ID, ADMINISTRATOR_ROLE_ID } from "../../consts";

function UserEditPage({ isMyPage = false }) {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const authUserId = useAuthUserId();
  const authUserRole = useAuthUserRole();
  const authUserPlace = useAuthUserPlace();

  const [user, setUser] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const [errors, setErrors] = useState({});

  const id = isMyPage ? authUserId : params.id;
  const isMySelf = user && user.id === authUserId;
  const title = id
    ? user
      ? `${user.staff_name}`
      : `ID:${id}`
    : "新規ユーザー";

  useTitle(
    () => (isMyPage ? "ユーザー設定" : `${title} | ユーザー管理`),
    title
  );

  useEffect(
    () => {
      if (!id) {
        setUser({
          id: null,
          place: {
            id: "",
            place_code: null,
            place_name: null
          },
          staff_code: null,
          staff_name: null,
          staff_kana: null,
          role: {
            id: "",
            label: null,
            basename: null
          },
          created_at: null,
          updated_at: null
        });
        return;
      }
      dispatch(apiRequest(fetchUser(id))).then(action => {
        if (apiRequest.fulfilled.match(action)) {
          setUser(action.payload);
          return;
        }
        setNotFound(true);
      });
    },
    [dispatch, id]
  );

  const onChange = e => {
    e.preventDefault();
    const data = { ...user };
    const name = e.target.name.replace(/_id$/, "");
    const value = e.target.value;
    data[name] = { ...user[name], id: value };
    setUser(data);
  };

  const onSubmit = e => {
    e.preventDefault();

    const data = formToObject(e.target);
    let saveAction;

    if (id) {
      data.id = id;
      if (data.password === "") {
        delete data.password;
      }
      saveAction = () => updateUser(data); // 更新
    } else {
      saveAction = () => storeUser(data); // 新規
    }

    saveAction()
      .then(() => {
        UIkit.notification({
          message: "ユーザー情報を保存しました",
          status: "primary",
          timeout: 2000
        });
        setErrors({});
        if (isMySelf) {
          dispatch(loginCheck());
        }
        if (!isMyPage) {
          navigate("/users");
        }
      })
      .catch(e => {
        let message = "登録エラー";
        const data = e.response.data;
        if (data) {
          if (data.message) {
            message = data.message;
          }
          if (data.errors) {
            setErrors(data.errors);
          }
        }
        UIkit.notification({
          message,
          status: "danger",
          timeout: 2000
        });
      });
  };

  return (
    <Container className="pg-videoList">
      {!notFound ? (
        <div>
          <div className="bl-pageHeader">
            <div className="uk-grid uk-flex-middle">
              <div className="uk-width-1-1">
                {isMyPage ? (
                  <h1 className="bl-pageHeader-title">ユーザー設定</h1>
                ) : (
                  <h1 className="bl-pageHeader-title">
                    <span className="uk-visible@s">
                      <Link to="/users">ユーザー管理</Link> &gt;{" "}
                    </span>
                    {title}
                  </h1>
                )}
              </div>
            </div>
          </div>
          {user ? (
            <form action="" method="post" onSubmit={onSubmit}>
              <div className="uk-grid">
                <div className="uk-width-1-2@m">
                  <div className="uk-form-horizontal uk-margin-large">
                    <div className="uk-margin">
                      <label className="uk-form-label">職員番号</label>
                      <div className="uk-form-controls">
                        <Error messages={errors.staff_code} />
                        {id ? (
                          <span className="uk-input uk-form-blank">
                            {user.staff_code}
                          </span>
                        ) : (
                          <input
                            type="tel"
                            name="staff_code"
                            defaultValue={user.staff_code}
                            className="uk-input"
                            autoComplete="username"
                          />
                        )}
                      </div>
                    </div>
                    <div className="uk-margin">
                      <label className="uk-form-label">職員名</label>
                      <div className="uk-form-controls">
                        <Error messages={errors.staff_name} />
                        <input
                          type="text"
                          name="staff_name"
                          defaultValue={user.staff_name}
                          className="uk-input"
                        />
                      </div>
                    </div>
                    <div className="uk-margin">
                      <label className="uk-form-label">職員名 フリガナ</label>
                      <div className="uk-form-controls">
                        <Error messages={errors.staff_kana} />
                        <input
                          type="text"
                          name="staff_kana"
                          defaultValue={user.staff_kana}
                          className="uk-input"
                        />
                      </div>
                    </div>
                    <div className="uk-margin">
                      <label className="uk-form-label">パスワード</label>
                      <div className="uk-form-controls">
                        <Error messages={errors.password} />
                        <input
                          type="password"
                          name="password"
                          defaultValue=""
                          className="uk-input"
                          autoComplete="new-password"
                        />
                      </div>
                    </div>
                    <div className="uk-margin">
                      <label
                        className="uk-form-label"
                        htmlFor="form-horizontal-select"
                      >
                        所属施設
                      </label>
                      <div className="uk-form-controls">
                        <Error messages={errors.place_id} />
                        {isMySelf && !authUserRole.partial_user_manage ? (
                          <span className="uk-input uk-form-blank">
                            {user.place.place_name}
                            <input
                              type="hidden"
                              name="place_id"
                              value={user.place.id}
                            />
                          </span>
                        ) : (
                          authUserRole.partial_facilitymanager_manage ? (
                            <span className="uk-input uk-form-blank">
                              {authUserPlace.place_name}
                              <input
                                type="hidden"
                                name="place_id"
                                value={authUserPlace.id}
                              />
                            </span>
                          ) : (
                            <PlaceSelect
                              defaultValue={user.place.id}
                              onChange={onChange}
                            />
                          )
                        )}
                      </div>
                    </div>
                    <div className="uk-margin">
                      <label
                        className="uk-form-label"
                        htmlFor="form-horizontal-select"
                      >
                        権限
                      </label>
                      <div className="uk-form-controls">
                        <Error messages={errors.role_id} />
                        {isMySelf || (authUserRole.partial_facilitymanager_manage && user.role.id === ADMINISTRATOR_ROLE_ID) ? (
                          <span className="uk-input uk-form-blank">
                            {user.role.label}
                            <input
                              type="hidden"
                              name="role_id"
                              value={user.role.id}
                            />
                          </span>
                        ) : (
                          <RoleSelect
                            defaultValue={user.role.id}
                            roleIdList={
                              authUserRole.partial_facilitymanager_manage
                                ? [FACILITY_MANAGER_ROLE_ID, STAFF_ROLE_ID]
                                : []
                            }
                            onChange={onChange}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bl-pageFooter">
                <div className="uk-grid uk-flex-right">
                  <div className="uk-width-1-2@m uk-text-right">
                    <button
                      type="submit"
                      className="uk-button uk-button-primary"
                    >
                      設定を保存
                    </button>
                  </div>
                </div>
              </div>
            </form>
          ) : null}
        </div>
      ) : (
        <div>
          <div className="bl-pageHeader">
            <div className="uk-grid uk-flex-middle">
              <div className="uk-width-1-1">
                <h1 className="bl-pageHeader-title">
                  <span className="uk-visible@s">
                    <Link to="/users">ユーザー管理</Link> &gt;{" "}
                  </span>
                  不明なユーザー
                </h1>
              </div>
            </div>
          </div>
          <p>
            該当するユーザーは存在しない、または 権限上の問題により
            操作できません。
          </p>
        </div>
      )}
    </Container>
  );
}

export default UserEditPage;
