import {useCallback, useEffect, useState} from "react";
import UIkit from "uikit";
import UserListRow from "./row";
import { deleteMultipleUsers } from "../../api/user";
import DeleteConfirmModal from "../common/modal/deleteconfirm";
import { useAuthUserId } from "../../hooks/authuser";

function UserList({ items, refresh = () => {} }) {
  const authUserId = useAuthUserId();
  const [selectedItems, setSelectedItems] = useState([]);
  const controlEnabled = selectedItems.length > 0;

  const isDeletable = useCallback(
    item => {
      return !item.deleted_at && authUserId !== item.id;
    },
    [authUserId]
  );

  const deletableItems = items.filter(isDeletable);

  const allItemsSelected = selectedItems.length >= deletableItems.length;

  const onChange = e => {
    const value = e.target.value;
    let items = [...selectedItems, value];
    if (!e.target.checked) {
      items = selectedItems.filter(id => id !== value);
    }
    setSelectedItems(items);
  };

  const selectReset = e => {
    setSelectedItems([]);
  };

  useEffect(() => {
    selectReset();
  }, [items]);

  const selectAll = e => {
    const selectedItems = allItemsSelected
      ? []
      : items.filter(isDeletable).map(item => String(item.id));
    setSelectedItems(selectedItems);
  };

  const SelectAllButton = () => {
    let checked = allItemsSelected ? "is-checked" : "";
    if (!checked && controlEnabled) {
      checked = "is-indeterminate";
    }
    return <button className={`uk-checkbox ${checked}`} onClick={selectAll} />;
  };

  const [isShowDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const confirmDeleteMultipleUsers = e => {
    e.preventDefault();
    setShowDeleteConfirm(true);
  };

  const deleteUsers = e => {
    (async () => {
      const ids = selectedItems;
      setSelectedItems([]);
      setShowDeleteConfirm(false);
      try {
        await deleteMultipleUsers(ids);
        UIkit.notification({
          message: "削除しました",
          status: "primary",
          timeout: 2000
        });
        refresh();
      } catch (e) {
        let message = "削除エラー";
        if (e.response.data && e.response.data.message) {
          message = e.response.data.message;
        }
        UIkit.notification({
          message,
          status: "danger",
          timeout: 2000
        });
      }
    })();
  };

  const onCancel = e => {
    setShowDeleteConfirm(false);
  };

  return (
    <div className="uk-overflow-auto">
      <table className="uk-table uk-table-middle uk-table-divider bl-videoList">
        <thead>
          {controlEnabled ? (
            <tr>
              <th className="uk-table-shrink">
                <SelectAllButton />
              </th>
              <th className="el-controlCell" colSpan="4">
                <span className="uk-text-bold">選択したユーザーを</span>{" "}
                <button
                  type="submit"
                  className="uk-button uk-button-small uk-button-danger"
                  onClick={confirmDeleteMultipleUsers}
                >
                  削除
                </button>
              </th>
            </tr>
          ) : (
            <tr>
              <th className="uk-table-shrink">
                <SelectAllButton />
              </th>
              <th className="uk-width-2-5@s">ユーザー</th>
              <th className="uk-table-expand uk-visible@s uk-text-nowrap">
                所属施設
              </th>
              <th className="uk-visible@s uk-table-shrink uk-text-nowrap">
                権限
              </th>
              <th className="uk-visible@s uk-table-shrink uk-text-nowrap">
                登録日
              </th>
            </tr>
          )}
          <tr className="el-emptyRow">
            <th className="uk-table-shrink" />
            <th className="uk-width-2-5@s" />
            <th className="uk-table-expand uk-visible@s uk-text-nowrap" />
            <th className="uk-visible@s uk-table-shrink uk-text-nowrap" />
            <th className="uk-visible@s uk-table-shrink uk-text-nowrap" />
          </tr>
        </thead>
        <tbody>
          {items.map(item => (
            <UserListRow
              id={`item-${item.id}`}
              key={`item-${item.id}`}
              user={item}
              onChange={onChange}
              checked={selectedItems.includes(String(item.id))}
            />
          ))}
        </tbody>
      </table>
      <DeleteConfirmModal
        show={isShowDeleteConfirm}
        onAccept={deleteUsers}
        onCancel={onCancel}
      >
        <p>
          <span>選択したユーザー</span>を削除してもよろしいですか？
          <br />
          （当該ユーザーはログインできなくなります）
        </p>
      </DeleteConfirmModal>
    </div>
  );
}

export default UserList;
