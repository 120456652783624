import {Container} from "uikit-react";

function Error403() {
  return (
    <Container className="pg-videoList">
      <div className="bl-pageHeader">
        <div className="uk-grid uk-flex-middle">
          <div className="uk-width-1-1">
            <h1 className="bl-pageHeader-title">403 Forbidden</h1>
          </div>
        </div>
      </div>
      <p>この画面の操作は許可されていません。管理者によって制限されている場合があります。</p>
    </Container>
  );
}

export default Error403;
