import { Link, NavLink, Outlet } from "react-router-dom";
import {
  Icon,
  Navbar,
  NavbarContainer,
  NavbarDropdown,
  NavbarSticky,
  NavItem
} from "uikit-react";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { reloadRoles } from "../stores/slices/roles";
import { reloadPlaces } from "../stores/slices/places";
import useAuthUser from "../hooks/authuser";
import { getEnvironmentName } from "../utilities";
import { reloadNotices } from "../stores/slices/notice";
import { 
  FACILITY_MANAGER_ROLE_ID, 
  ADMINISTRATOR_ROLE_ID 
} from "../consts";


const getMenu = (roleId) => {
  switch (roleId) {
    case ADMINISTRATOR_ROLE_ID: 
    {
      return [
        {
          link: "/videos",
          icon: "video-camera",
          title: "動画管理",
        },
        {
          link: "/users",
          icon: "users",
          title: "ユーザー管理",
        },
        {
          link: "/settings",
          icon: "cog",
          title: "システム設定",
        },
      ];
    }
    case FACILITY_MANAGER_ROLE_ID: 
    {
      return [
        {
          link: "/videos",
          icon: "video-camera",
          title: "動画管理",
        },
        {
          link: "/users",
          icon: "users",
          title: "ユーザー管理",
        },
      ];
    }
    default:
      return [];
  }
};

function Dashboard({ noNavigation = false }) {
  const user = useAuthUser();
  const [productName, setProductName] = useState("Peony"); //productNameの初期値は"Peony"に設定
  const [noticeInfos, setNoticeInfo] = useState([]);

  const headerMenus = getMenu(user.role.id);

  const dispatch = useDispatch();
  useEffect(
    () => {
      if (user.id) {
        dispatch(reloadRoles());
        dispatch(reloadPlaces());
      }

      dispatch(reloadNotices()).then(action => {
        if (reloadNotices.fulfilled.match(action)) {
          setNoticeInfo(action.payload);
        }
      });  

      // ドメインによってproductNameを設定
      const environmentName = getEnvironmentName();
      switch (environmentName) {
        case "production":
          break;
        case "test":
          setProductName("Peony (テスト環境)");
          break;
        case "local":
          setProductName("Peony (ローカル環境)");
          break;
        default:
          break;
      }
    },
    [dispatch, user.id]
  );

  return (
    <div className="ly-dashboard">
      <NavbarSticky width="1-1" className="ly-header">
        <NavbarContainer className="ly-header-main">
          <Navbar left>
            <NavItem className="ly-header-logo">
              <Link to={"/videos"}>{productName}</Link>
            </NavItem>
          </Navbar>
          <Navbar right>
            {noNavigation ? (
              <NavItem className="uk-navbar-item">&nbsp;</NavItem>
            ) : user.id ? (
              <>
                <NavItem>
                  <span className="uk-navbar-item el-fg-white">
                    {user.place.place_name}
                  </span>
                </NavItem>
                <NavItem>
                  {/* eslint-disable-next-line */}
                  <a href="#">
                    <Icon options="icon: user" />{" "}
                    <span className="uk-visible@s uk-margin-small-left">
                      職員番号: {user.staff_code}
                      <br />
                      {user.staff_name}
                    </span>
                  </a>
                  <NavbarDropdown className="data">
                    <NavItem>
                      <Link to="/account">
                        <Icon options="icon: user" />
                        ユーザー設定
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link to="/logout">
                        <Icon options="icon: sign-out" />
                        ログアウト
                      </Link>
                    </NavItem>
                  </NavbarDropdown>
                </NavItem>
              </>
            ) : (
              <NavItem>
                {/* eslint-disable-next-line */}
                <Link to="/login">
                  <Icon options="icon: user" />{" "}
                  <span className="uk-visible@s uk-margin-small-left">
                    ログイン
                  </span>
                </Link>
              </NavItem>
            )}
          </Navbar>
        </NavbarContainer>
        {!noNavigation && user ? (
            headerMenus.length > 0 ? (
            <Navbar className="ly-header-sub">
              {headerMenus.map((menu, index) => {
                return <NavItem  key={index}>
                  <NavLink to={menu.link}>
                    <Icon options={`icon: ${menu.icon}`} />
                    {menu.title}
                  </NavLink>
                </NavItem>
              })}
            </Navbar>
            ) : null
        ) : null}
        {noticeInfos.length > 0 ? (
          <Navbar className="ly-header-notice">
            {noticeInfos.map((noticeInfo, index) => (
              <NavItem key={index}>
                {noticeInfo.message}
              </NavItem>
            ))}
          </Navbar>
        ) : null}
      </NavbarSticky>
      <div className="ly-body">
        <main className="ly-main">
          <Outlet />
        </main>
      </div>
    </div>
  );
}

export default Dashboard;
