import { Link } from "react-router-dom";
import { Icon, Label } from "uikit-react";
import {dateFormat, durationFormat} from "../../utilities";
import Checkbox from "../common/element/checkbox";

function VideoListRow({
  deletable = true,
  video = { user: {} },
  checked = false,
  onChange = e => {},
  onDelete = e => {},
  onRestore = e => {}
}) {
  return (
    <tr id={"row-" + video.id} className="bl-videoList-row">
      <td>
        <Checkbox
          onChange={onChange}
          value={video.id}
          checked={checked}
          disabled={!deletable}
          className={`${!deletable ? "uk-invisible" : ""}`}
        />
      </td>
      <td>
        <div className="bl-videoList-item">
          <div className="bl-videoList-item-inner">
            <div className="bl-videoList-item-thumbnail">
              <div
                className="bl-videoList-item-thumbnail-img"
                style={{
                  backgroundImage: video.thumbnail
                    ? `url(${video.thumbnail})`
                    : "none"
                }}
              />
              {video.duration ? (
                <span className="bl-videoList-item-thumbnail-time">
                  {durationFormat(video.duration)}
                </span>
              ) : null}
            </div>
            <div className="bl-videoList-item-info">
              <div className="bl-videoList-item-title">
                {deletable ? (
                  <Link to={`/videos/edit/${video.id}`}>
                    {video.patient_code || "(患者ID未設定)"}&nbsp;{video.patient_name || "(患者名未設定)"}
                  </Link>
                ) : (
                  <span>{video.patient_code || "(患者ID未設定)"}&nbsp;{video.patient_name || "(患者名未設定)"}</span>
                )}
                {!video.encoded_at ? (
                  video.failed_at ? (
                    <Label color="danger" content="変換失敗" />
                  ) : (
                    <Label color="warning" content="変換処理中" />
                  )
                ) : null}
                {video.removed ? (
                  <span>
                    {video.expired ? (
                      <Label color="danger" content="期限切れ" />
                    ) : null}
                    {video.exceeded ? (
                      <Label color="danger" content="回数超え" />
                    ) : null}
                    {!video.expired && !video.exceeded && !video.failed_at ? (
                      <Label color="danger" content="削除済み" />
                    ) : null}
                  </span>
                ) : null}
              </div>
              {video.scheduled_unlink_at ? (
                <div className="bl-videoList-item-description">
                  物理削除予定日:{" "}
                  {dateFormat(video.scheduled_unlink_at, "yyyy/M/d")}
                </div>
              ) : null}
              <div className="bl-videoList-item-description uk-hidden@s">
                アップロード日: {dateFormat(video.created_at)}
                <br />
                視聴期限: {dateFormat(video.expired_at, "yyyy/M/d", "無期限")}
                <br />
                視聴回数: {video.watch_count} / {video.limit_count || "無制限"}
                <br />
              </div>

              <ul className="bl-videoList-item-command">
                {deletable ? (
                  <>
                    <li>
                      <Link to={`/videos/edit/${video.id}`}>
                        <span className="uk-text-middle uk-visible@m">
                          <Icon options="icon:pencil" />
                          &nbsp;
                        </span>
                        <span className="uk-text-middle">編集</span>
                      </Link>
                    </li>
                    {video.encoded_at ? (
                      <li>
                        <Link to={`/watch/${video.basename}`}>
                          <span className="uk-text-middle uk-visible@m">
                            <Icon options="icon:play-circle" />
                            &nbsp;
                          </span>
                          <span className="uk-text-middle">再生</span>
                        </Link>
                      </li>
                    ) : null}
                    <li>
                      <a
                        href={`/pdf/${video.basename}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="uk-text-middle uk-visible@m">
                          <Icon options="icon:print" />
                          &nbsp;
                        </span>
                        <span className="uk-text-middle">QR表示</span>
                      </a>
                    </li>
                    <li>
                      <button
                        type="button"
                        className="uk-button uk-button-link uk-text-danger"
                        onClick={onDelete}
                      >
                        <span className="uk-text-middle uk-visible@m">
                          <Icon options="icon:trash" />
                          &nbsp;
                        </span>
                        <span className="uk-text-middle">削除</span>
                      </button>
                    </li>
                  </>
                ) : null}
                {video.removed && !video.failed_at ? (
                  <li>
                    <button
                      type="button"
                      className="uk-button uk-button-link uk-text-danger"
                      onClick={onRestore}
                    >
                      <span className="uk-text-middle uk-visible@m">
                        <Icon options="icon:history" />
                        &nbsp;
                      </span>
                      <span className="uk-text-middle">復元して編集</span>
                    </button>
                  </li>
                ) : null}
              </ul>
            </div>
          </div>
        </div>
      </td>
      <td className="uk-visible@s">
        {dateFormat(video.encoded_at || video.created_at)}
        <br />
        {video.user.staff_name}
      </td>
      <td className="uk-visible@s uk-text-nowrap">
        {dateFormat(video.expired_at, "yyyy/M/d", "無期限")}
      </td>
      <td className="uk-visible@s uk-text-nowrap uk-text-right">
        {video.watch_count} / {video.limit_count || "無制限"}
      </td>
    </tr>
  );
}

export default VideoListRow;
