import { useSelector } from "react-redux";
import { roleSelector } from "../../stores/slices/roles";
import Select from "./element/select";
import { useState, useEffect } from "react";

function RoleSelect({ defaultValue = "", roleIdList = [], onChange = e => {} }) {
  const roles = useSelector(roleSelector.selectAll);
  const [roleLists, setRoles] = useState([]); 

  useEffect(() => {
    // roleIdListが設定されている場合、roleIdListのリストを設定
    if (roleIdList.length > 0) {
      const filteredRoles = roles.filter(role => roleIdList.includes(role.id));
        setRoles(filteredRoles);
    } else {
      setRoles(roles);
    }
  }, [roles, roleIdList]);

  return (
    <Select
      name="role_id"
      defaultValue={defaultValue}
      empty="権限を選択..."
      items={roleLists.map(role => ({ label: role.label, value: role.id }))}
      onChange={onChange}
    />
  );
}

export default RoleSelect;
