import { useSelector } from "react-redux";
import { placeSelector } from "../../stores/slices/places";
import Select from "./element/select";

function PlaceSelect({
  empty = "施設を選択...",
  defaultValue = "",
  onChange = e => {}
}) {
  const places = useSelector(placeSelector.selectAll);

  return (
    <Select
      name="place_id"
      defaultValue={defaultValue}
      empty={empty}
      items={places.map(place => ({
        label: place.place_name,
        value: place.id
      }))}
      onChange={onChange}
    />
  );
}

export default PlaceSelect;
