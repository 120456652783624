import { Link } from "react-router-dom";
import Checkbox from "../common/element/checkbox";
import { dateFormat } from "../../utilities";
import { useAuthUserId } from "../../hooks/authuser";

function UserListRow({ user = {}, checked = false, onChange = (e) => {} }) {
  const authUserId = useAuthUserId();

  return (
    <tr className="bl-videoList-row">
      <td>
        <Checkbox
          onChange={onChange}
          value={user.id}
          checked={checked}
          disabled={user.id === authUserId}
          className={`${user.id === authUserId ? "uk-invisible" : null}`}
        />
      </td>
      <td className="uk-text-bold">
        <Link to={`/users/edit/${user.id}`} className="uk-link-reset">
          {user.staff_code} {user.staff_name}
        </Link>
      </td>
      <td data-testid="place-name" className="uk-visible@s">
        {user.place.place_name}
      </td>
      <td data-testid="role-label" className="uk-visible@s uk-text-nowrap">
        {user.role.label}
      </td>
      <td className="uk-visible@s uk-text-nowrap">
        {dateFormat(user.created_at)}
      </td>
    </tr>
  );
}

export default UserListRow;
