import ConfirmModal from "./confirm";

function DeleteConfirmModal({
  show = false,
  onAccept = e => {},
  onCancel = e => {},
  children
}) {
  return (
    <ConfirmModal
      id="js-modal-delete"
      accept="削除"
      cancel="キャンセル"
      show={show}
      onAccept={onAccept}
      onCancel={onCancel}
      theme="danger"
    >
      {children || null}
    </ConfirmModal>
  );
}
export default DeleteConfirmModal;
