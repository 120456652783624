import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardTitle,
  Form,
  FormLabel,
  Icon,
  InputContainer
} from "uikit-react";
import { useNavigate } from "react-router-dom";
import { login } from "../../stores/slices/auth";
import UIkit from "uikit";

function LoginForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isFailed, setFailed] = useState(false);

  const isLoading = useSelector(state => state.auth.loading);
  const shake = !isLoading && isFailed ? "uk-animation-shake" : "";
  const username = useRef();
  const password = useRef();

  const onSubmit = e => {
    e.preventDefault();
    dispatch(
      login({
        username: username.current.value,
        password: password.current.value
      })
    ).then(action => {
      if (login.fulfilled.match(action)) {
        const user = action.payload;
        if (user.password_temporary === true) { // 仮登録の場合
            navigate("/password/reset_by_user");
          } else { // 本登録の場合
            navigate("/videos");
          }
      } else if (login.rejected.match(action)) {
        setFailed(true);
        UIkit.notification({
          message: "認証できません",
          status: "danger",
          timeout: 2000
        });
      }
    });
  };

  return (
    <Card className={`uk-width-1-1 uk-width-large@s uk-margin-top ${shake}`}>
      <CardBody>
        <CardTitle className="uk-text-center uk-margin-medium-bottom">
          Peony
        </CardTitle>
        <form action="" method="post" onSubmit={onSubmit}>
          <Form layout="stacked">
            <div className="uk-margin">
              <InputContainer>
                <FormLabel className="uk-inline" width="1-1">
                  <Icon className="uk-form-icon" options="user" />
                  <input
                    id="staffcode"
                    type="text"
                    name="username"
                    className="uk-input"
                    autoComplete="username"
                    placeholder="職員番号"
                    ref={username}
                  />
                </FormLabel>
              </InputContainer>
            </div>
            <div className="uk-margin">
              <InputContainer>
                <FormLabel className="uk-inline" width="1-1">
                  <Icon className="uk-form-icon" options="lock" />
                  <input
                    id="password"
                    type="password"
                    name="password"
                    className="uk-input"
                    autoComplete="current-password"
                    placeholder="パスワード"
                    ref={password}
                  />
                </FormLabel>
              </InputContainer>
            </div>
            <div className="uk-margin-medium-top uk-text-center">
              <button
                id="login"
                type="submit"
                className="uk-button uk-button-primary"
                disabled={isLoading}
              >
                ログイン
                {isLoading ? (
                  <span
                    className="uk-margin-left"
                    data-uk-spinner="ratio:0.5"
                  />
                ) : null}
              </button>
            </div>
          </Form>
        </form>
      </CardBody>
    </Card>
  );
}

export default LoginForm;
