import React, { useRef } from "react";
import { Form, FormLabel, InputContainer } from "uikit-react";
import { useNavigate } from "react-router-dom";
import UIkit from "uikit";
import { passwordResetRequestByUser } from "../../api/password";

function PasswordResetByUserForm() {
  const navigate = useNavigate();
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();

  const onSubmit = e => {
    e.preventDefault();
    (async () => {
      const newPassword = passwordRef.current.value;
      const confirmPassword = confirmPasswordRef.current.value;

      if (newPassword !== confirmPassword) {
        UIkit.notification({
          message: "入力したパスワードが一致しません",
          status: "danger",
          timeout: 2000,
        });
        return;
      }

      try {
        await passwordResetRequestByUser(newPassword);
        UIkit.notification({
          message: "パスワードを変更しました<br>自動でログイン画面に戻ります",
          status: "success",
          timeout: 2000
        });
        setTimeout(() => navigate("/login"), 2000); // 2秒後にログイン画面へリダイレクト
      } catch (e) {
        let message = "パスワードを変更できません";
        if (e.response && e.response.data && e.response.data.message) {
          message = e.response.data.message;
        }
        if (e.response && e.response.data && e.response.data.errors) { // バリデーションエラー
          const errors = e.response.data.errors;
          message = errors[Object.keys(errors)[0]][0];
        }
        UIkit.notification({
          message,
          status: "danger",
          timeout: 2000
        });
      }
    })();
  };  

  return (
    <form action="" method="post" onSubmit={onSubmit}>
      <Form layout="stacked">
        <div className="uk-margin">
          <InputContainer>
            <FormLabel className="uk-inline uk-margin" width="1-1">
              <input
                type="password"
                id="newPassword"
                name="new_password"
                className="uk-input"
                autoComplete="new-password"
                placeholder="変更後のパスワード"
                ref={passwordRef}
              />
            </FormLabel>
            <FormLabel className="uk-inline" width="1-1">
              <input
                type="password"
                id="confirmPassword"
                name="confirm_password"
                className="uk-input"
                autoComplete="new-password"
                placeholder="変更後のパスワードを再入力"
                ref={confirmPasswordRef}
              />
            </FormLabel>
          </InputContainer>
        </div>
        <div className="uk-margin-medium-top uk-text-center">
          <button id="changePassword" type="submit" className="uk-button uk-button-primary">
            パスワードを変更
          </button>
        </div>
      </Form>
    </form>
  );
}

export default PasswordResetByUserForm;
