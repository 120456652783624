import { useSearchParams } from "react-router-dom";

function Pagination({ query, current, last, limit = 5 }) {
  const [, setSearchParams] = useSearchParams();

  const limitHalf = Math.floor(limit / 2);
  const limitEven = limitHalf * 2;

  const first = 1;
  const prev = Math.max(first, current - 1);
  const next = Math.min(last, current + 1);

  let begin = Math.max(1, current - limitHalf);
  let end = Math.min(current + limitHalf, last);
  if (end - begin < limitEven) {
    if (begin === 1) {
      end = Math.min(limit, last);
    }
    if (end === last) {
      begin = Math.max(1, end - limitEven);
    }
  }

  const pages = [];
  for (let p = begin; p <= end; p++) {
    pages.push(p);
  }

  const setPage = p => {
    const newQuery = {};
    Object.keys(query).forEach(key => {
      const value = query[key];
      if (value != null && value !== "") {
        newQuery[key] = value;
      }
    });
    setSearchParams({ ...newQuery, p });
  };

  return (
    <ul className="uk-pagination uk-flex-center">
      <li className={current === first ? "uk-disabled" : ""}>
        <span onClick={e => setPage(prev)}>
          <span data-uk-pagination-previous />
        </span>
      </li>
      {current > first + 2 && begin !== first ? (
        <li className={current === first ? "uk-active" : ""}>
          <span onClick={e => setPage(first)}>
            <span>{first}</span>
          </span>
        </li>
      ) : null}
      {current > first + 3 && begin - 1 > first ? (
        <li className="uk-disabled">
          <span>...</span>
        </li>
      ) : null}
      {pages.map(p => (
        <li key={`page-${p}`} className={p === current ? "uk-active" : ""}>
          <span onClick={e => setPage(p)}>
            <span>{p}</span>
          </span>
        </li>
      ))}
      {current < last - 3 && end + 1 < last ? (
        <li className="uk-disabled">
          <span>...</span>
        </li>
      ) : null}
      {current < last - 2 && end !== last ? (
        <li className={current === last ? "uk-active" : ""}>
          <span onClick={e => setPage(last)}>
            <span>{last}</span>
          </span>
        </li>
      ) : null}
      <li className={current === last ? "uk-disabled" : ""}>
        <span onClick={e => setPage(next)}>
          <span data-uk-pagination-next />
        </span>
      </li>
    </ul>
  );
}

export default Pagination;
