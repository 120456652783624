import Alert from "../common/alert";
import { dateFormat } from "../../utilities";

function VideoAlerts({
  video,
  isUploading,
  isUploaded,
  isUploadError,
  isProcessing,
  isFailed,
  isSaved,
  uploadPercentage,
  progressBar
}) {
  return (
    <>
      {isUploading ? (
        <Alert theme="primary" icon="spinner" title="アップロードの進行中">
          <p className="uk-text-small">
            現在 動画をアップロード中（{uploadPercentage}
            %）です。画面は閉じないでください。この間に以下の情報入力を進めてください。
          </p>
          <progress
            defaultValue="0"
            max="100"
            ref={progressBar}
            className="uk-progress"
          />
        </Alert>
      ) : isUploaded ? (
        isUploadError ? (
          <Alert theme="danger" icon="warning" title="動画のアップロードに失敗しました">
            <p className="uk-text-small">
              {/* 一応 uploadError でエラーの原因を取得できるが、ここでのエラーは十中八九形式の問題 */}
              お手数ですが、動画の形式をご確認の上、一覧画面に戻って
              再度お試しください。
            </p>
          </Alert>
        ) : (
          <Alert theme="success" icon="check" title="アップロード済み">
            <p className="uk-text-small">
              動画のアップロードは完了しました。
              {isSaved ? null : (
                <span>
                  入力内容を確認の上、最後に保存ボタンを押せば完了です。
                </span>
              )}
            </p>
          </Alert>
        )
      ) : null}
      {!isUploadError && isProcessing ? (
        <Alert
          theme="warning"
          icon="clock"
          title={
            <span>
              動画変換処理中
              <span className="uk-visible@s">のため まだ再生できません</span>
            </span>
          }
        >
          <p className="uk-text-small">
            PCやスマートフォンでブラウザで動画を再生するには
            アップロード動画の形式を変換する必要があるため、現在
            変換処理をおこなっています。
            <br />
            これには
            5～30分程度時間がかかる場合があります。恐れ入りますが、少し時間を置いてから再度ご確認ください。
            <br />
            {video.estimated_encoded_at ? (
              <span>
                推定完了時刻: {dateFormat(video.estimated_encoded_at)}
              </span>
            ) : null}
            （サーバー上で処理されるので画面を開いておく必要はありません）
          </p>
        </Alert>
      ) : null}
      {!isUploadError && isFailed ? (
        <Alert theme="danger" icon="warning" title="動画変換に失敗しました">
          <p className="uk-text-small">
            PCやスマートフォンでブラウザで動画を再生するには
            アップロード動画の形式を変換する必要があるため、変換処理をおこないましたが、失敗しました。
            <br />
            お手数ですが、動画の形式を変更して新規動画としてアップロードし直してみてください。
          </p>
        </Alert>
      ) : null}
    </>
  );
}

export default VideoAlerts;
