import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Container, Icon } from "uikit-react";
import { searchVideos } from "../../api/video";
import { useFullQuery, useCompactQuery } from "../../hooks/query";
import { useAuthUserPlace, useAuthUserRole } from "../../hooks/authuser";
import Pagination from "../common/pagination";
import SearchBox from "../common/searchbox";
import PlaceFilter from "../common/placefilter";
import StatusFilter from "./statusfilter";
import VideoList from "./list";
import VideoUploadModal from "./upload";
import { apiRequest } from "../../stores/slices/auth";
import useTitle from "../../hooks/title";

function VideoListPage() {
  useTitle("動画管理");

  const dispatch = useDispatch();
  const [response, setResponse] = useState();
  const authUserRole = useAuthUserRole();
  const authUserPlace = useAuthUserPlace();

  const fullQuery = useFullQuery(
    useMemo(
      () => ({
        p: 1,
        s: "",
        placeId: authUserPlace.id,
        deletedOnly: ""
      }),
      [authUserPlace.id]
    )
  );

  const query = useCompactQuery(fullQuery);
  const [update, setUpdate] = useState(null);

  useEffect(
    () => {
      dispatch(apiRequest(searchVideos(fullQuery))).then(action => {
        if (apiRequest.fulfilled.match(action)) {
          setResponse(action.payload);
        }
      });
    },
    [dispatch, fullQuery, update]
  );

  const refresh = e => {
    setUpdate(Date.now());
  };

  const [modalIsActive, setModalIsActive] = useState(false);
  const [timer, setTimer] = useState(0);
  const [openByClick, setOpenByClick] = useState(false);

  const closeModal = () => {
    setOpenByClick(false);
    setModalIsActive(false);
  };

  const onDragOver = useCallback(
    e => {
      e.preventDefault();
      setModalIsActive(true);
      setTimer(
        setTimeout(() => {
          if (!openByClick) {
            closeModal(false);
          }
        }, 500)
      );
    },
    [openByClick]
  );

  useEffect(
    () => {
      document.addEventListener("dragover", onDragOver, false);
      return () => {
        document.removeEventListener("dragover", onDragOver, false);
      };
    },
    [onDragOver]
  );

  useEffect(
    () => {
      return () => {
        clearTimeout(timer);
      };
    },
    [timer]
  );

  const upload = e => {
    setOpenByClick(true);
    setModalIsActive(true);
  };

  return (
    <Container className="pg-videoList">
      <div className="bl-pageHeader">
        <div className="uk-grid uk-flex-middle">
          <div className="uk-width-1-3">
            <h1 className="bl-pageHeader-title">動画管理</h1>
          </div>
          <div className="uk-width-2-3 uk-text-right">
            <button
              type="button"
              className="uk-button uk-button-primary"
              onClick={upload}
            >
              <Icon
                options="icon: plus-circle;"
                className="uk-text-middle uk-margin-small-right"
              />
              <span className="uk-text-middle">
                <span className="uk-visible@s">新しく動画を</span>アップロード
              </span>
            </button>
          </div>
        </div>
        <div className="uk-grid uk-flex-between uk-flex-middle uk-margin">
          <div className="uk-width-2-3@s">
            {authUserRole.manage_all_videos ? (
              <span className="uk-margin-small-right">
                <PlaceFilter query={fullQuery} />
              </span>
            ) : null}
            <StatusFilter query={fullQuery} />
          </div>
          <div className="uk-width-1-3@s uk-text-right uk-margin-top uk-margin-remove-top@s uk-width-1-4@l">
            <SearchBox query={fullQuery} placeholder="患者IDまたは患者名で検索" />
          </div>
        </div>
      </div>
      {response ? (
        response.data.length ? (
          <div>
            <VideoList
              query={fullQuery}
              items={response.data}
              refresh={refresh}
            />
            <div className="bl-pageFooter">
              <Pagination
                query={query}
                current={response.current_page}
                last={response.last_page}
              />
            </div>
          </div>
        ) : (
          <p className="uk-text-middle">
            該当する動画が見つかりません。
            <br />
            条件を変えてお試しください。
          </p>
        )
      ) : null}
      {modalIsActive ? <VideoUploadModal close={closeModal} /> : null}
    </Container>
  );
}

export default VideoListPage;
