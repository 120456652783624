import { apiGet, apiPost } from "./api";

export async function authenticate(staff_code, password) {
  const response = await apiPost("/auth/login", {
    staff_code,
    password
  });
  const user = response.data;
  window.sessionStorage.user = JSON.stringify(user);
  return user;
}

export async function refreshUser() {
  const response = await apiGet("/auth/user");
  const user = response.data;
  window.sessionStorage.user = JSON.stringify(user);
  return user;
}

export async function revoke() {
  window.sessionStorage.removeItem("user");
  await apiPost("/auth/logout");
}
