import { Card, CardBody, CardTitle, Container, Flex } from "uikit-react";
import { Link, Outlet } from "react-router-dom";
import useTitle from "../../hooks/title";

function PasswordResetPage() {
  useTitle("パスワード変更");

  return (
    <Container width="1-1" background="muted" className="pg-login">
      <Flex height="viewport" alignment="center middle">
        <div>
          <Card className="uk-width-1-1 uk-width-large@s uk-margin-top">
            <CardBody>
              <CardTitle className="uk-text-center uk-margin-medium-bottom">
                パスワード変更
              </CardTitle>
              <Outlet />
            </CardBody>
          </Card>
          <p className="uk-text-center">
            <Link to="/login" className="uk-link-muted">
              ログインページに戻る
            </Link>
          </p>
        </div>
      </Flex>
    </Container>
  );
}

export default PasswordResetPage;
