import { Icon } from "uikit-react";

function Alert({ theme = "warning", icon = "info", title = "", children }) {
  return (
    <div className={`uk-alert el-bg-${theme} uk-margin-medium-bottom`}>
      <h3 className="uk-heading-small">
        {icon === "spinner" ? (
          <span
            className="uk-text-middle uk-margin-small-right"
            data-uk-spinner
          />
        ) : (
          <Icon
            options={`icon: ${icon}; ratio: 2`}
            className="uk-text-middle uk-margin-small-right"
          />
        )}
        <span className="uk-text-middle">{title}</span>
      </h3>
      {children}
    </div>
  );
}

export default Alert;
