import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Container, Icon } from "uikit-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import UIkit from "uikit";
import { useDispatch, useSelector } from "react-redux";
import { fetchVideo, updateVideo } from "../../api/video";
import Error from "../common/error";
import { dateFormat, formToObject } from "../../utilities";
import { complete } from "../../stores/slices/upload";
import Player from "../common/player";
import { apiRequest } from "../../stores/slices/auth";
import useTitle from "../../hooks/title";
import useAuthUser from "../../hooks/authuser";
import VideoAlerts from "./alerts";
import { useBlocker } from "../../hooks/blocker";
import ConfirmModal from "../common/modal/confirm";
import { ADMINISTRATOR_ROLE_ID, FACILITY_MANAGER_ROLE_ID } from "../../consts";

const EditableVideoLimitDays = ({ video, errors }) => {
  return (
    <div className="uk-margin">
      <label className="uk-form-label" htmlFor="video_limit_days">
        再生可能期間（0で無期限）
      </label>
      <div className="uk-form-controls">
        <Error messages={errors.limit_days} />
        <input
          id="video_limit_days"
          type="number"
          name="limit_days"
          defaultValue={video.limit_days}
          className="uk-input uk-form-width-xsmall uk-text-center"
          min="0"
        />
        <span className="uk-inline uk-width-auto uk-input uk-form-blank">
          日間（現在{video.elapsed_days || 0}日目）
        </span>
      </div>
    </div>
  );
};

const UneditableVideoLimitDays = ({ video }) => {
  return (
    <div className="uk-margin">
      <label className="uk-form-label">
        再生可能期間
      </label>
      <div className="uk-form-controls">
        <span className="uk-inline uk-width-auto uk-input uk-form-blank">
          {video.limit_days}日間（現在{video.elapsed_days || 0}日目）
        </span>
        <input
          id="video_limit_days"
          type="hidden"
          name="limit_days"
          value={video.limit_days}
        />
      </div>
    </div>
  );
};

const EditableVideoLimitCount = ({ video, errors }) => {
  return (
    <div className="uk-margin">
      <label className="uk-form-label" htmlFor="video_limit_count">
        再生可能回数（0で無制限）
      </label>
      <div className="uk-form-controls">
        <Error messages={errors.limit_count} />
        <input
          id="video_limit_count"
          type="number"
          name="limit_count"
          defaultValue={video.limit_count}
          className="uk-input uk-form-width-xsmall uk-text-center"
          min="0"
        />
        <span className="uk-inline uk-width-auto uk-input uk-form-blank">
          回（{video.watch_count || 0}回再生済）
        </span>
      </div>
    </div>
  );
};

const UneditableVideoLimitCount = ({ video }) => {
  return (
    <div className="uk-margin">
      <label className="uk-form-label">
        再生可能回数
      </label>
      <div className="uk-form-controls">
        <span className="uk-inline uk-width-auto uk-input uk-form-blank">
          {video.limit_count}回（{video.watch_count || 0}回再生済）
        </span>
        <input
          id="video_limit_count"
          type="hidden"
          name="limit_count"
          value={video.limit_count}
        />
      </div>
    </div>
  );
};

function VideoEditPage(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const user = useAuthUser();

  const [video, setVideo] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const [errors, setErrors] = useState({});
  const [isSaved, setSaved] = useState(true);
  const [isUploaded, setUploaded] = useState(false);

  const id = props.id || params.id;

  const uploadVideo = useSelector(state => state.upload.video);
  const uploadPercentage = useSelector(state => state.upload.percentage);
  const uploadCompleted = useSelector(state => state.upload.completed);
  const uploadError = useSelector(state => state.upload.error);

  const isNew = Number(params.id) === uploadVideo.id && !uploadCompleted;
  const isUploading = useSelector(state => state.upload.uploading) && isNew;
  const isUploadError = isNew && uploadError;
  const isProcessing =
    video && !isUploading && !video.encoded_at && !video.failed_at;
  const isFailed = video && video.failed_at;
  const isPlayable = !isUploading && !isProcessing && !isFailed;

  const canEditVideoSettings = [
    ADMINISTRATOR_ROLE_ID,
    FACILITY_MANAGER_ROLE_ID,
  ].includes(user.role.id);

  const title = useMemo(
    () => {
      if (notFound) return "不明な動画";
      if (!video) return `ID:${id}`;
      if (isUploadError) return "エラー";
      if (isUploading) return "新規アップロード";
      return video.patient_name || "(患者名未設定)";
    },
    [id, notFound, video, isUploadError, isUploading]
  );

  useTitle(() => `${title} | 動画管理`, title);

  const progressBar = useRef();

  useEffect(
    () => {
      if (!progressBar.current) return;
      progressBar.current.value = uploadPercentage;
    },
    [uploadPercentage]
  );

  useEffect(
    () => {
      if (isNew && !isUploading) {
        setUploaded(true);
        if (!isUploadError && isSaved) {
          dispatch(complete());
        }
      }
    },
    [dispatch, isNew, isUploading, isSaved, isUploadError]
  );

  useEffect(
    () => {
      if (isNew) {
        setSaved(false);
      }
    },
    [isNew]
  );

  useBlocker(
    useCallback(
      tx => {
        const message = isUploading
          ? "アップロードが完了するまでお待ちください"
          : "先に動画情報を入力、保存してください";
        UIkit.notification({
          message,
          status: "danger",
          timeout: 2000
        });
        //tx.retry();
      },
      [isUploading]
    ),
    !isUploadError && (!isSaved || isUploading)
  );

  useEffect(
    () => {
      if (isUploading) {
        setVideo(uploadVideo);
        return;
      }
      dispatch(apiRequest(fetchVideo(id))).then(action => {
        if (apiRequest.fulfilled.match(action)) {
          setVideo(action.payload);
          return;
        }
        setNotFound(true);
      });
    },
    [dispatch, isUploading, id, uploadVideo]
  );

  const form = useRef();
  const [isShowConfirm, setShowConfirm] = useState(false);
  const [isExpired, setExpired] = useState(false);
  const [isExceed, setExceed] = useState(false);

  const save = useCallback(
    () => {
      const data = formToObject(form.current);
      data.id = id;

      updateVideo(data)
        .then(() => {
          (async () => {
            setSaved(true);
            UIkit.notification({
              message: "動画の情報を保存しました",
              status: "primary",
              timeout: 2000
            });
            setErrors({});
            if (!isUploading) {
              navigate("/videos");
            }
          })();
        })
        .catch(e => {
          console.log(e);
          let message = "登録エラー";
          if (e.response && e.response.data) {
            const data = e.response.data;
            if (data.message) {
              message = data.message;
            }
            if (data.errors) {
              setErrors(data.errors);
            }
          }
          UIkit.notification({
            message,
            status: "danger",
            timeout: 2000
          });
        });
    },
    [id, navigate, isUploading]
  );

  const onSubmit = useCallback(
    e => {
      e.preventDefault();

      const data = formToObject(form.current);
      const isExpired =
        Number(data.limit_days) && Number(video.elapsed_days || 0) > Number(data.limit_days);
      const isExceed =
        Number(data.limit_count) && Number(video.watch_count) >= Number(data.limit_count);

      //先に set して state を参照してしまうと useCallback が意味を為さなくなるので
      //ローカル変数の結果を state に保存するようにする
      setExpired(isExpired);
      setExceed(isExceed);

      if (isExpired || isExceed) {
        setShowConfirm(true);
        return;
      }

      save();
    },
    [video, save]
  );

  const onAccept = () => {
    setShowConfirm(false);
    save();
  };

  const onCancel = () => {
    setShowConfirm(false);
  };

  return (
    <Container className="pg-videoEdit">
      <div>
        <div className="bl-pageHeader">
          <div className="uk-grid uk-flex-middle">
            <div className="uk-width-3-4">
              <h1 className="bl-pageHeader-title">
                <span className="uk-visible@s">
                  <Link to="/videos">動画管理</Link> &gt;{" "}
                </span>
                {title}
              </h1>
            </div>
            <div className="uk-width-1-4 uk-text-right">
              {video && !isUploading && !isUploadError ? (
                <a
                  href={`/pdf/${video.basename}`}
                  className="uk-button uk-button-primary"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icon
                    options="icon: print;"
                    className="uk-text-middle uk-margin-small-right"
                  />
                  <span className="uk-text-middle">
                    QR<span className="uk-visible@s">を印刷</span>
                  </span>
                </a>
              ) : null}
            </div>
          </div>
        </div>
        {video ? (
          <>
            <VideoAlerts
              video={video}
              isUploading={isUploading}
              isUploaded={isUploaded}
              isUploadError={isUploadError}
              isProcessing={isProcessing}
              isFailed={isFailed}
              isSaved={isSaved}
              uploadPercentage={uploadPercentage}
              progressBar={progressBar}
            />
            {isPlayable ? (
              <Player video={video} className="uk-margin-medium-bottom" />
            ) : null}
            {!isUploadError ? (
              <form action="" method="post" onSubmit={onSubmit} ref={form}>
                <div className="uk-grid">
                  <div className="uk-width-1-2@m uk-margin-bottom">
                    <div className="uk-form-horizontal uk-margin-large">
                      <div className="uk-margin">
                        <h3 className="uk-heading-small">患者情報</h3>
                      </div>
                      <div className="uk-margin">
                        <label className="uk-form-label">患者ID</label>
                        <div className="uk-form-controls">
                          <Error messages={errors.patient_code} />
                          <input
                            type="text"
                            name="patient_code"
                            defaultValue={video.patient_code}
                            className="uk-input"
                          />
                        </div>
                      </div>
                      <div className="uk-margin">
                        <label className="uk-form-label">患者名</label>
                        <div className="uk-form-controls">
                          <Error messages={errors.patient_name} />
                          <input
                            type="text"
                            name="patient_name"
                            defaultValue={video.patient_name}
                            className="uk-input"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="uk-width-1-2@m">
                    <div className="uk-form-horizontal uk-margin-large">
                      <div className="uk-margin">
                        <h3 className="uk-heading-small">動画情報</h3>
                      </div>

                      {/* 再生可能期間 */}
                      {canEditVideoSettings
                        ? (<EditableVideoLimitDays video={video} errors={errors} />)
                        : (<UneditableVideoLimitDays video={video} />)}

                      {/* 再生可能回数 */}
                      {canEditVideoSettings
                        ? (<EditableVideoLimitCount video={video} errors={errors} />)
                        : (<UneditableVideoLimitCount video={video} />)}

                      {video.uploaded_at ? (
                        <div className="uk-margin">
                          <label className="uk-form-label">
                            アップロード日
                          </label>
                          <div className="uk-form-controls">
                            <span className="uk-input uk-form-blank">
                              {dateFormat(video.uploaded_at)}
                            </span>
                          </div>
                        </div>
                      ) : null}
                      <div className="uk-margin">
                        <label
                          className="uk-form-label"
                          htmlFor="form-horizontal-select"
                        >
                          アップロードユーザー
                        </label>
                        <div className="uk-form-controls">
                          {isNew ? (
                            <span className="uk-input uk-form-blank">
                              {user.place.place_name} {user.staff_name}
                            </span>
                          ) : (
                            <span className="uk-input uk-form-blank">
                              {video.place ? video.place.place_name : null}{" "}
                              {video.user ? video.user.staff_name : null}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bl-pageFooter">
                  <div className="uk-grid uk-flex-right">
                    <div className="uk-width-1-2@m uk-text-right">
                      <button
                        type="submit"
                        className="uk-button uk-button-primary"
                      >
                        動画の情報を保存
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            ) : null}
          </>
        ) : notFound ? (
          <p>
            該当する動画は存在しない、または 権限上の問題により操作できません。
          </p>
        ) : null}
      </div>
      <ConfirmModal
        show={isShowConfirm}
        accept="それでも保存する"
        onAccept={onAccept}
        onCancel={onCancel}
      >
        {isExpired ? <p>既に再生可能日数を経過しています。</p> : null}
        {isExceed ? (
          <p>{isExpired ? "また、" : null}既に再生可能回数を超えています。</p>
        ) : null}
        <p>
          このまま保存すると再生できなくなりますが、本当によろしいですか？
        </p>
      </ConfirmModal>
    </Container>
  );
}

export default VideoEditPage;
